import React, { Suspense, useEffect } from "react";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import { initFirebase } from "./firebaseInit";
import SignUpContainer from "./containers/SignUpContainer";
import DoctorSignUpContainer from "./containers/DoctorSignUpContainer";
import LoginContainer from "./containers/LoginContainer";
import Dashboard from "./containers/Dashboard";
import TestComponent from "./components/boxes/TestComponent";
import "./App.css";
import "./index.css";
import ResetPasswordContainer from "./containers/ResetPasswordContainer";
import PlanExpiredPage from "./components/PlanExpiredPage";
import PaymentSuccessfulPage from "./components/PaymentSuccessfulPage";
import LogoutContainer from "./containers/LogoutContainer";
import { withUser } from "./components/utils/AuthHOC";
import LanguageSwitcher from "./components/languageSwitcher/LanguageSwitcher";
import { PulseLoader } from "react-spinners";
import { useFeatureFlags } from "./components/utils/FeatureFlagWrapper";
import ReferContainer from "./containers/ReferContainer";
import { sessionService } from "redux-react-session";
import { logoutUser } from "./actions/user";

const clearStorageOnce = async () => {
  const isCleared = localStorage.getItem("storageCleared");

  if (isCleared === "true") {
    return;
  }

  try {
    sessionStorage.clear();
    await sessionService.deleteSession();
    await sessionService.deleteUser();

    // Clear local storage (after setting the flag)
    localStorage.clear();
    localStorage.setItem("storageCleared", "true");

    // Clear all cookies
    document.cookie.split(";").forEach((cookie) => {
      const [name] = cookie.split("=");
      document.cookie = `${name}=;expires=${new Date(0).toUTCString()};path=/`;
    });
    window.location.href = "/logout";
  } catch (error) {
    console.error("Error while clearing storage:", error);
  }
};

const App = () => {
  const { flags } = useFeatureFlags();

  if (!process.env.DISABLE_FIREBASE) {
    initFirebase();
  }
  useEffect(() => {
    const clearStorage = async () => {
      await clearStorageOnce();
    };
    clearStorage();
  }, []);

  return (
    <Suspense
      fallback={
        <div className="loader-fb">
          <PulseLoader color="#094063" size={7} />
        </div>
      }
    >
      <Router history="">
        <div className="content dash">
          {flags.featureLanguageOptions && <LanguageSwitcher />}
          <Switch>
            <Route component={LoginContainer} path="/login" exact />
            <Route component={SignUpContainer} path="/register" exact />
            <Route
              component={DoctorSignUpContainer}
              path="/doctor-signup"
              exact
            />
            <Route
              component={ResetPasswordContainer}
              path="/reset-password"
              exact
            />
            <Route component={TestComponent} path="/test" exact />
            <Route component={PlanExpiredPage} path="/plan-expired" exact />
            <Route
              component={PaymentSuccessfulPage}
              path="/payment-successful"
              exact
            />
            <Route component={LogoutContainer} path="/logout" exact />
            <Route component={withUser(ReferContainer)} path="/refer" />
            <Route component={withUser(Dashboard)} path="/" />
          </Switch>
        </div>
      </Router>
    </Suspense>
  );
};

export default App;
