import axios from "axios";
import querystring from "querystring";
import {
  BASE_URL_RELIANCE,
  getCookie,
  DOMAIN_COOKIE_NAME,
  DAARA_REFRESH_TOKEN,
  DAARA_COOKIE_NAME,
} from "./utilityService";
import { isFeatureEnabled } from "@reliance/feature-flags";
import { features } from "./constants";

const BASE_URL = BASE_URL_RELIANCE;

export const ENDPOINTS = {
  base: `${BASE_URL}/enrollees`,
  doctor: {
    register: "doctors/profile",
    retrieveStatus: (doctorId) => `/doctors/${doctorId}/status`,
    utilities: "/utility/specialties",
  },
  feedbackThread: {
    postFeedback: "/enrollees/feedback",
    getFeedbackThreads: "/enrollees/feedback/thread",
    getFeedbackThreadById: (id) => `/enrollees/feedback/thread/${id}`,
    postFeedbackToThread: (id) => `/enrollees/feedback/thread/${id}`,
  },
  consultations: {
    consultation: "/consultations",
    consultationDetails: (id) => `/consultations/${id}`,
    status: (id) => `/consultations/${id}/status`,
    messages: (id) => `/consultations/${id}/messages`,
    prescriptions: (id, prescriptionId) =>
      `/consultations/${id}/prescriptions/${prescriptionId}/new`,
    prescriptionsPickup: (id, prescriptionId) =>
      `/consultations/${id}/prescriptions/${prescriptionId}`,
    prescriptionsDelivery: (consultationId, prescriptionId) =>
      `/consultations/${consultationId}/prescriptions/${prescriptionId}/delivery`,
    prescriptionsProvider: (id, prescriptionId) =>
      `/consultations/${id}/prescriptions/${prescriptionId}/provider`,
    complaints: "/consultations/complaints",
    referrals: (id, referralId) =>
      `consultations/${id}/referrals/${referralId}`,
    rating: (id) => `consultations/${id}/ratings`,
    lgas: `/utility/lgas`,
  },
  profile: {
    profileDetails: "/enrollees/profile",
  },
  pharmacies: {
    allPharmacies: "consultations/pharmacies",
  },
};

export class ApiService {
  #daaraToken;
  #refreshToken;
  #data;
  #accessToken;
  #ax;
  #staticAccessToken;

  constructor(accessToken = "", lang = "en", BASE = BASE_URL) {
    this.#daaraToken = getCookie(DAARA_COOKIE_NAME);
    this.#refreshToken = getCookie(DAARA_REFRESH_TOKEN);
    this.#staticAccessToken = getCookie(DOMAIN_COOKIE_NAME);

    this.#accessToken =
      accessToken ||
      (isFeatureEnabled(features.jwtToken)
        ? this.#daaraToken
        : this.#staticAccessToken);

    this.#ax = axios.create({
      baseURL: BASE,
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${this.#accessToken}`,
      },
      params: {
        lang: lang === "ar" ? "translated" : "",
      },
    });

    this.#ax.interceptors.response.use(
      (res) => res,
      this.handleAuthError.bind(this)
    );
  }

  async handleAuthError(error) {
    const originalRequest = error.config;

    if (error.response?.status === 401 && !originalRequest._retry) {
      this.logout();
    }

    return Promise.reject(error);
  }

  logout() {
    window.location.href = `/logout`;
  }

  async post(url, data) {
    try {
      const response = await this.#ax.post(url, data);
      return response.data;
    } catch (e) {
      throw new ApiServiceException(e);
    }
  }

  async put(url, data) {
    try {
      const response = await this.#ax.put(url, data);
      return response.data;
    } catch (e) {
      throw new ApiServiceException(e);
    }
  }

  async patch(url, data) {
    try {
      const response = await this.#ax.patch(url, data);
      return response.data;
    } catch (e) {
      throw new ApiServiceException(e);
    }
  }

  async get(url, data) {
    try {
      const response = await this.#ax.get(
        `${url}?${querystring.stringify(data)}`
      );
      return response.data;
    } catch (e) {
      throw new ApiServiceException(e);
    }
  }
}

class ApiServiceException extends Error {
  constructor(e, _meta = {}) {
    const defaultError = {
      response: {
        status: 0,
        statusText: "Unknown Error",
        data: { message: "An Error Occurred" },
      },
    };

    e = e.response ? e : defaultError;

    const message =
      e.response.data.message ||
      e.response.statusText ||
      e.response.message ||
      "An Error occurred";
    super(message);

    this.code = e.response.status;
    this.status = e.response.status;
    this.message = message;

    this.meta = {
      ..._meta,
      url: e.config.url,
      baseURL: e.config.baseURL,
      params: { ...e.config.params },
      method: e.config.method,
      success: false,
      timestamp: new Date().toISOString(),
    };

    if (Error.captureStackTrace) {
      Error.captureStackTrace(this, ApiServiceException);
    }
  }
}

export default new ApiService();
