import React from "react";

export default function tourHospitals(t, i18n) {
  return [
    {
      selector: '[data-tut="step_five_tour"]',
      content: ({ goTo }) => (
        <div
          dir={i18n.dir()}
          style={{
            marginRight: i18n.dir() === "rtl" ? "10px" : "0",
          }}
        >
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <h4
              style={{
                color: "#fff",
                fontSize: "18px",
                fontWeight: "700",
                lineHeight: "22px",
                marginBottom: "12px",
              }}
            >
              {t("tour:my_hospitals-tour.my_hospitals")}
            </h4>
          </div>
          <p
            style={{
              color: "#fff",
              fontSize: "14px",
              fontWeight: "400",
              lineHeight: "20px",
              marginBottom: "16px",
            }}
          >
            {t("tour:my_hospitals-tour.my_hospitals_text")}
          </p>
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <p
              style={{
                color: "#fff",
                fontSize: "14px",
                fontWeight: "700",
                lineHeight: "17px",
              }}
            >
              {t("tour:general-tour.step1/2")}
            </p>
            <div
              style={{
                display: "flex",
              }}
            >
              <button
                className="tour-next"
                style={{
                  background: "#00508E",
                  padding: "8px 32px",
                  borderRadius: "5px",
                  fontSize: "14px",
                  display: "block",
                  cursor: "pointer",
                  border: "0",
                  color: "#fff",
                }}
                onClick={() => goTo(1)}
              >
                {t("tour:general-tour.next")}
              </button>
            </div>
          </div>
        </div>
      ),
      style: {
        padding: "24px 32px",
        minWidth: "407px",
        background: "#003F75",
      },
    },
    {
      selector: '[data-tut="step_search_hospital_tour"]',
      content: ({ goTo }) => (
        <div
          dir={i18n.dir()}
          style={{
            marginRight: i18n.dir() === "rtl" ? "10px" : "0",
          }}
        >
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <h4
              style={{
                color: "#fff",
                fontSize: "18px",
                fontWeight: "700",
                lineHeight: "22px",
                marginBottom: "12px",
              }}
            >
              {t("tour:general-tour.search")}
            </h4>
          </div>
          <p
            style={{
              color: "#fff",
              fontSize: "14px",
              fontWeight: "400",
              lineHeight: "20px",
              marginBottom: "16px",
            }}
          >
            {t("tour:my_hospitals-tour.search_text")}
          </p>
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <p
              style={{
                color: "#fff",
                fontSize: "14px",
                fontWeight: "700",
                lineHeight: "17px",
              }}
            >
              {t("tour:general-tour.step2/2")}
            </p>
            <div
              style={{
                display: "flex",
              }}
            >
              <button
                style={{
                  background: "#00508E",
                  padding: "8px 32px",
                  borderRadius: "5px",
                  fontSize: "14px",
                  display: "block",
                  cursor: "pointer",
                  border: "0",
                  color: "#fff",
                  marginRight: i18n.dir() === "rtl" ? "0" : "8px",
                  marginLeft: i18n.dir() === "rtl" ? "8px" : "0",
                }}
                onClick={() => goTo(0)}
              >
                {t("tour:general-tour.prev")}
              </button>
              <button
                className="tour-next"
                style={{
                  background: "#00508E",
                  padding: "8px 32px",
                  borderRadius: "5px",
                  fontSize: "14px",
                  display: "block",
                  cursor: "pointer",
                  border: "0",
                  color: "#fff",
                }}
                onClick={() => (window.location.href = "/benefits")}
              >
                {t("tour:general-tour.next")}
              </button>
            </div>
          </div>
        </div>
      ),
      style: {
        padding: "24px 32px",
        minWidth: "407px",
        background: "#003F75",
      },
    },
  ];
}
