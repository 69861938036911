import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import LoadingScreeen from "../components/LoadingScreen";
import MainContent from "../components/boxes/MainContent";
import PageHeader from "../components/boxes/PageHeader";
import PageBody from "../components/boxes/PageBody";
import ChatThreadList from "../components/ChatThreadList";
import { ApiService, ENDPOINTS } from "../services/ApiService";
import { useTranslation, withTranslation } from "react-i18next";

class ConsultDoctorContainer extends Component {
  constructor(props) {
    super(props);
    this.service = new ApiService(
      props.user.credentials.accessToken,
      props.i18n.language
    );
    this.state = {
      isLoading: false,
      consultations: [],
    };
  }

  componentDidMount() {
    // temporarily took out loader for modal to be displayed for Egypt
    // this.setLoading(true);
    this.service
      .get(ENDPOINTS.consultations.consultation)
      .then((response) => {
        this.setState({
          isLoading: false,
          consultations: response.data,
        });
      })
      .catch((e) => {
        console.error(e);
      });
  }

  setLoading(isLoading) {
    this.setState({
      isLoading,
    });
  }

  render() {
    const { isLoading, consultations } = this.state;
    return (
      <MainContent>
        <PageHeader
          title={
            isLoading
              ? `${this.props.t("sidebar.consultations")}`
              : `${this.props.t("sidebar.consult_doctor")}`
          }
        />
        <PageBody>
          {isLoading ? (
            <LoadingScreeen />
          ) : (
            <ChatThreadList chatThreadList={consultations} />
          )}
        </PageBody>
      </MainContent>
    );
  }
}
const ConsultDoctorContainerWithTranslation = withTranslation(["webapp"])(
  ConsultDoctorContainer
);
export default withRouter(ConsultDoctorContainerWithTranslation);
